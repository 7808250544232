<template>
  <div>
    <div class="banner" style="position: relative">
      <Swiper ref="mySwiper" :pagination="true" :options="swiperOptions">
        <SwiperSlide>
          <el-image
            style="width: 100%"
            :src="require('@/assets/banner.png')"
          ></el-image
        ></SwiperSlide>
        <SwiperSlide>
          <el-image
            style="width: 100%"
            :src="require('@/assets/banner1.png')"
          ></el-image>
        </SwiperSlide>
        <SwiperSlide>
          <el-image
            style="width: 100%"
            :src="require('@/assets/banner2.png')"
          ></el-image
        ></SwiperSlide>
               <SwiperSlide>
          <el-image
            style="width: 100%"
            :src="require('@/assets/banner3.png')"
          ></el-image
        ></SwiperSlide>
      </Swiper>
      <div class="swiper-pagination" style="left: 50%; bottom: 20px"></div>
    </div>

    <div class="main">
      <!--企业服务-->
      <div class="enterprise-services-container">
        <div class="menuHeader">
          <div class="englishtitle">ENTERPRISE SERVICES</div>
          <div class="title">企业服务</div>
          <div class="desc">技术不断提升</div>
        </div>

        <div class="enterprise-services">
          <div v-for="(enterpriseService, i) in enterpriseServices" :key="i">
            <div
              v-if="enterpriseService.isactive === false"
              class="enterprise-service"
              @mouseover="changeItem(enterpriseService, i)"
            >
              <img :src="enterpriseService.iconDefault" class="servicesicon" />
              <p>0{{ i + 1 }}</p>
              <p class="title">{{ enterpriseService.title }}</p>

              <div class="linecontainer">
                <div class="line"></div>
              </div>
              <div class="descContainer">
                <p class="simple-desc">{{ enterpriseService.simpleDesc }}</p>
              </div>

              <div
                style="
                  margin-top: 52px;
                  width: 8px;
                  height: 8px;
                  background: #e1e1e1;
                  border-radius: 50%;
                "
              />
            </div>

            <div v-else class="status-active">
              <!--企业服务状态二-->
              <div class="active-left">
                <div class="active-left-content">
                  <img
                    :src="enterpriseService.iconActive"
                    class="servicesicon"
                  />
                  <p class="index">0{{ i + 1 }}</p>
                  <p class="title">{{ enterpriseService.title }}</p>

                  <div class="linecontainer">
                    <div class="line"></div>
                  </div>

                  <div class="btn" @click="enterpriseServiceClickByenterpriseService(enterpriseService)" style="cursor: pointer;">
                    <el-image
                      :src="require('@/assets/icon-right.png')"
                      style="width: 40px"
                    />
                    点击使用
                  </div>
                </div>
              </div>

              <div class="active-right">
                <div class="desc">
                  {{ enterpriseService.desc }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--海关管理-->
      <div class="Customs-Administration">
        <div class="left">
          <div class="content">
            <div
              style="font-size: 48px; font-family: Arial; font-weight: bold"
              class="gradual-change"
            >
              CUSTOMS
            </div>
            <div
              style="font-size: 28px; font-family: Arial; font-weight: bold"
              class="gradual-change"
            >
              ADMINISTRATION
            </div>

            <div class="title">海关管理</div>
            <div class="desc">向科技要效率</div>
          </div>
        </div>

        <div class="right">
          <div class="tabs">
            <div
              v-for="(tab, i) in customsAdministrationTabs"
              :key="i"
              class="normal"
              :class="{ 'customs-administration-active': tab.isactive }"
              @click="changecustomsAdministrationTab(tab)"
            >
              <div style="font-size: 12px; font-weight: 400">0{{ i + 1 }}</div>
              <div>{{ tab.title }}</div>
              <div class="pointline">
                <div
                  style="
                    width: 5px;
                    height: 5px;
                    background: #ffffff;
                    border-radius: 50%;
                  "
                />
                <div
                  style="height: 1px; background: #ffffff; width: 100%"
                ></div>
              </div>
            </div>
          </div>
          {{ currentCustomsAdministrationTab.simpleTitle }}
          <p
            v-html="currentCustomsAdministrationTab.desc"
            style="height: 180px"
          ></p>

          <div class="btn" @click="addJump(currentCustomsAdministrationTab.title)" :class="currentCustomsAdministrationTab.title == '跨境电商辅助管理' ? 'notClick' : ''" >点击使用</div>
        </div>
      </div>

      <!--公共服务-->

      <div class="public-service-container">
        <div class="menuHeader">
          <div class="englishtitle">PUBLIC SERVICE</div>
          <div class="title">公共服务</div>
          <div class="desc">便利公共服务</div>
        </div>

        <div class="maincontent" style="height: 400px">
          <div :span="12" class="left">
            <div class="leftContent">
              <div class="title">跨境电商通关服务</div>
              <div class="desc">
                跨境电商通关服务
                跨境电商进出口通关服务：跨境电商通关申报系统汇集跨境电商企业进出口申报数据，为企业跨境电商进出口业务申报提供辅助通关功能，无需登录多个系统，实现进出口申报数据一键查询功能；提供企业、进出口商品、车辆信息提前备案操作，提高申报效率，便于地方政府适应政策变化，进行提前管控，随时掌握本地区跨境贸易产业发展情况
              </div>
              <div class="btn" @click="kjdsClick" style="cursor: no-drop;">点击使用</div>
            </div>
          </div>
          <div :span="10" class="right">
            <div class="rightContent" style="margin-top: 57px">
              <el-image
                style="width: 373px"
                :src="require('@/assets/kjds.png')"
              ></el-image>
            </div>
          </div>
        </div>

        <div class="maincontent class4-3">
          <div class="left leftbg">
            <div class="leftContent">
              <el-image
                style="width: 402px"
                :src="require('@/assets/kjfdkdh.png')"
              ></el-image>
              <div style="color: #ffffff">跨境电商数据可视化</div>
              <div class="desc" style="color: #ffffff">
                跨境电商数据多维度展示：基于通关全流程数据进行分析；全流程展示企业通关数据，分析通关耗时，提高通关效率；基于企业跨境贸易数据进行分析，实现跨境贸易数据可视化，面向不同的用户提供多功能、多视角的展示服务，为企业掌握自身经营状况、提升自身竞争能力提供数据支撑；提供跨境贸易数据大屏展示功能，便于企业及政府对跨境贸易数据进行全流程的实时追踪，提供企业竞争力
              </div>
              <div
                class="btn"
                style="color: #ffffff; border: 1px solid #ffffff;cursor: no-drop"
              >
                点击使用
              </div>
            </div>
          </div>
          <div class="right ">
            <div class="rightContent" style="margin-top: 167px">
              <div class="title title-gradual-change">监管场所智能化作业</div>
              <div class="desc">
                邮、快件监管仓作业：通关辅助仓管子系统为企业提供对邮件和快件监管仓辅助作业功能，主要包括进仓前的数据申报，仓位查询等，提供出仓前的数据申报及货物转报关申报，做到邮、快件申报数据随时可查，提高出仓效率；支持税单批量打印，实现监管场所智能化作业
              </div>

              <div
                class="btn"
                @click="jgclick"
                style="
                  color: #fff;
                  border: 1px solid #4484e4;
                  margin: 70px 0 43px 0;background: #4484e4;cursor: pointer;
                "
              >
                点击使用
              </div>

              <el-image
                style="width: 477px"
                :src="require('@/assets/kjdskshc.png')"
              ></el-image>
            </div>
          </div>
        </div>

        <div class="maincontent" style="height: 871px">
          <div class="left">
            <div class="leftContent">
              <el-image
                style="width: 400px"
                :src="require('@/assets/zhyq.png')"
              ></el-image>
              <div style="title">妥投管理</div>
              <div class="desc">
                追踪所有包裹的物流轨迹，智能分类包裹状态：签收异常，妥投，延期，一目了然。助力电商提升物流环节的用户体验与服务质量，专业团队维护，稳定可靠，值得信赖
              </div>
              <div
                class="btn"
                style="color: #b6b6b6; border: 1px solid #b6b6b6;cursor: no-drop"
              >
                点击使用
              </div>
            </div>
          </div>
          <div class="right class4-4" >
            <div class="rightContent" style="margin-top: 78px">
              <div class="title title-gradual-change">智慧园区</div>
              <div class="desc">
                园区综合智能化运行监控平台：平台为海关、园区管委会、区内企业等提供针对人员、车辆和货物等综合监控与管理解决应用方案，以智能设备、大数据和云计算、AI技术为核心，实现海关特殊监管区域和场所管理的智慧监管数字化、智能设备一体化、日常管理可视化、车货验放便利化、人员进出有序化，提升海关监管服务能力，提高园区通关时效水平，实现园区作业的“可视、可知、可查、可控”。为园区管委会、区内企业提供车辆、人员、货物开展业务的作业管理和业务监控。主要包括：视频智能监控系统、园区运行综合管理系统等。实现从人员、车辆、货物从入区到出区的全流程智能化识别监控管理，按照设置参数分析识别异常行为和反馈风险信息，实现人车货全链路智能化运行监控管理
              </div>

              <div
                class="btn"
                style="
                  color: #b6b6b6;
                  border: 1px solid #b6b6b6;
                  margin: 70px 0 43px 0;cursor: no-drop
                "
              >
                点击使用
              </div>

              <el-image
                style="width: 364px"
                :src="require('@/assets/ttgl.png')"
              ></el-image>
            </div>
          </div>
        </div>
      </div>

      <!--增值服务-->

      <div class="value-added-services-container">
        <div class="menuHeader">
          <div class="englishtitle">VALUE ADDED SERVICES</div>
          <div class="title">增值服务</div>
          <div class="desc">便利通关添砖加瓦</div>
        </div>

        <div class="added-services-content" v-if="addservice">
          <div class="left">
            <el-image
              style="width: 373px"
              class="leftimage"
              :src="require('@/assets/smrz.png')"
            ></el-image>
            <div class="textcontent">
              <div class="title">实名认证</div>
              <div class="desc">
                针对用户资料真实性进行的验证审核，以便建立完善
                可靠的互联网信用基础。完全在线操作，解决了传统
                流程人力投入过多，成本过大和工作效率低的问题。
                能够有效推进政府智能化办公的改进，提高政府办事
                效率，减少错误率，减少公众路途奔波。
              </div>

              <div class="btn" @click="smclcik" style="cursor: pointer;">点击使用</div>
            </div>
          </div>

          <div class="right" @mouseover="addservice=false">
            <div class="right-content">
              <p class="index">02</p>

              <p class="title">全球商品价格参考库</p>

              <p>...</p>
              <p class="desc">
                高效检索电商平台的商品相关信息 自动匹配对应上线型号进行一键绑定
              </p>
            </div>
          </div>
        </div>

        <div class="added-services-content" v-else>
            <div class="right" @mouseover="addservice=true">
            <div class="right-content">
              <p class="index">01</p>

              <p class="title">实名认证</p>

              <p>...</p>
              <p class="desc">
          在线操作解决人力投入过多、成本过大和
          效率低等问题，提高效率，降低错误率
              </p>
            </div>
          </div>
          <div class="left">
            <el-image
              style="width: 373px"
              class="leftimage"
              :src="require('@/assets/smrz.png')"
            ></el-image>
            <div class="textcontent">
              <div class="title">全球商品价格参考库</div>
              <div class="desc">
                通过研发的搜索引擎技术，高效检索电商平台的商品
相关信息，自动匹配对应上线型号进行一键绑定。7X
24小时不间断数据更新，确保每日信息的透明度；多
源多渠道采集，拥有验证码自动识别技术。采用中英
文混合索引等技术；分层分级进行数据挖掘并标签化
              </div>

              <div class="btn" @click="enterpriseServiceClick" style="cursor: no-drop;border-color: #b6b6b6;color: #b6b6b6;">点击使用</div>
            </div>
          </div>

        
        </div>
      </div>

      <!--客户介绍-->

      <div class="customer-container">
        <div class="menuHeader">
          <div class="englishtitle">CUSTOMER</div>
          <div class="title">客户介绍</div>
          <div class="desc">优质客户合作共赢</div>
        </div>
        <div class="customers-content-container">
          <div class="customers">
            <div class="customer" v-for="(customer, i) in customers" :key="i">
              <el-image :src="customer.src"></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>

        <Bottom></Bottom>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import Bottom from "@/components/Home/Bottom";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import SwiperCore, { Autoplay, Pagination } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);
let timeoutID;

export default {
  name: "index",

  components: {
    Swiper,
    SwiperSlide,
    Bottom
  },
  data() {
    return {
      addservice:true,
      swiperOptions: {
        spaceBetween: 50,
        centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        loop: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      enterpriseServices: [
        {
          iconDefault: require("@/assets/kjsb-default.png"),
          iconActive: require("@/assets/kjsb.png"),
          isactive: false,
          title: "快件申报——B类",
          simpleDesc: "实现跨境电商企业与海关标准化申报信息的直接交互",
          desc: "快件申报系统通过与中国国际贸易单一窗口快件申报系统对接，实现与海关总署的数据交互，优化申报流程，满足电子化、信息化的申报要求，企业可以实现快速、高效的报关和海关回执查询，提高报关企业工作效率；系统中的身份验核功能可实现申报前的身份预验核，大幅度降低企业退单率，推动快件业务规范运行、健康发展",
        },
        {
          iconDefault: require("@/assets/kjds-default.png"),
          iconActive: require("@/assets/kjsb.png"),
          isactive: false,

          title: "跨境电商申报 ——9610出口",
          simpleDesc: "实现跨境电商企业与海关标准化申报信息的直接交互",
          desc: "澎湃9610出口跨境平台通过与跨境电商出口统一版系统直接对接，减少通关过程中的数据流转环节，实现跨境电商企业与海关标准化申报信息的直接交互，利用信息化手段实现单证提前校验，解决企业在通关过程中难以快速通关、规范通关的痛点和难点，提升企业通关体验",
        },
        {
          isactive: false,

          iconDefault: require("@/assets/zgksh-default.png"),
          iconActive: require("@/assets/kjsb.png"),

          title: "转关可视化查询",
          simpleDesc: "协助企业进行转关前的申报，提高转关效率",
          desc: "转关可视化查询系统实现了对转关车辆四周进行360度全景录像，采用北斗信号与GPS信号为一体的惯导芯片，使车辆定位更精准，极大的提高了对转关车辆的定位力度，提供高清视频监控、360°全景、动静态辅助线显示等人性化功能，适用车型广泛，便于企业随时查看转关车辆信息，协助企业进行转关前的申报，提高转关效率",
        },
        // {
        //   isactive: false,

        //   iconDefault: require("@/assets/spznsb-default.png"),
        //   iconActive: require("@/assets/kjsb.png"),

        //   title: "商品智能识图作业",
        //   simpleDesc: "实现化繁为简，加强通关协作，优化通关模式",
        //   desc: "商品智能识图作业系统为企业提供了一款集跨境报关、单证预验核、消费者身份验核、识图中心为一体的综合申报辅助平台，监管场所经营人企业可通过该系统实现与海关的数据交互，系统上实现化繁为简，加强通关协作，优化通关模式，为企业提供公平、透明、便于的通关环境，更好地推动企业发展跨境电子商务业务",
        // },
      ],

      customsAdministrationTabs: [
        {
          title: "跨境电商辅助管理",
          isactive: true,
          simpleTitle: "辅助管理",
          desc: `- 跨境电商进出口通关管理：跨境电商通关申报系统汇聚跨境电商进出口通关数据，为海关跨境电商进出口监管业务提供辅助管理功能<br/>
                - 包括企业登记管理、进出口商品备案、核放管理、进出口申报单证管理、进出口申报单证查询，进出口退单管理、进口税单管理等<br/>
                - 便于海关对进出口通关数据进行灵活监管和统计，实现通关数据的全流程管控<br/>
                - 提前对企业进行登记管理，随时掌握通关企业的相关信息<br/>
                - 根据各地海关实际需求对进出口商品进行提前备案，对于禁止类进行通关类的商品可提前进行管制<br/>`,
        },
        //         {
        //           title: "智能识图监管",
        //           isactive: false,
        //           simpleTitle: "智能识图监管",
        //           desc: `- 智能识图监管系统利于技术化手段实现了海关监管作业场所的完全信息化操作<br/>
        // - 高效率地实现了操作留痕，数据可查，不仅可以提升海关监控效率，也为企业快速通关带来了极大便利<br/>
        // - 可根据各地海关的个性化需求定制创新监管模式，提升现场海关业务效率，做到监管可查、可管、可控<br/>
        // - 利于信息化手段做到“源头可溯、责任可究”<br/>`,
        //         },
        {
          title: "转关可视化监管",
          isactive: false,
          simpleTitle: "转关可视化监管",
          desc: `- 转关可视化监管系统融合了360全景环视系统技术，采用全景影像拼接和图像均衡算法<br/>
- 对转关车辆四周进行360度全景监控，采用北斗信号与GPS信号为一体的惯导芯片<br/>
- 使车辆定位更精准，极大的提高了对转关车辆的监控力度<br/>
- 提供高清视频监控、360°全景、动静态辅助线显示等定制化功能，适用车型广泛<br/>
- 便于海关随时掌控转关车辆信息，提高转关监管效率，有效降低转关风险<br/>`,
        },
        {
          title: "监管场所智能化管理",
          isactive: false,
          simpleTitle: "监管场所智能化管理",
          desc: `- 邮、快件监管仓管理：进出镜邮快件通关辅助管理系统为海关提供对邮件和快件监管仓的辅助管理功能<br/>
- 包括邮快件进仓前的数据接收，仓位绑定及查询等，海关可定期对仓位货物进行盘点，优化配置<br/>
- 提供出仓前的数据审核及货物类邮件转报关处理<br/>
- 海关也可根据实际需要实时或者定期对仓库信息进行维护<br/>
- 实现快速出仓、准确处理，提高监管效率<br/>
- 实现监管场所的智能化管理`,
        },
      ],
      currentCustomsAdministrationTab: {},
      customers: [],
    };
  },

  mounted() {
    this.currentCustomsAdministrationTab = this.customsAdministrationTabs[0];
    for (let i = 1; i <= 12; i++) {
      this.customers.push({
        src: require(`@/assets/custom${i}.png`),
      });
    }
    console.log(this.customers);
  },
  methods: {
    addJump(title){
      console.log(title)
      switch(title){
        case '转关可视化监管':
                          window.open("http://bd.pongpie.cn/808gps/login.html", "_blank");

          break
        case '监管场所智能化管理':
                          window.open("http://cc.vaneying.cn/", "_blank");

          break
        default:

          break
      }
    },
    smclcik(){
                          window.open("https://hy.pongpie.cn/login.html", "_blank");

    },
    jgclick(){
                          window.open("http://cc.vaneying.cn/", "_blank");

    },
    kjdsClick(){
                          //window.open("http://210.12.102.156:8081/CES/dist/index.html#/jkspbagl/I_GdPermitList", "_blank");

    },
    enterpriseServiceClickByenterpriseService(item){
      switch(item.title){
        case '跨境电商申报 ——9610出口':
                          window.open("https://9610.pongpie.cn/", "_blank");

          break
        case '转关可视化查询':
                          window.open("http://bd.pongpie.cn/808gps/login.html", "_blank");

          break
        default:
                window.open("https://bg.pongpie.cn/loginController.do?login", "_blank");

          break
      }

    },
    enterpriseServiceClick(item) {
      //TODO 跳转

      //window.open("https://bg.pongpie.cn/loginController.do?login", "_blank");
    },
    changeItem(enterpriseService) {
      console.log("changeItem");
      //TODO 变更点击项状态与布局
      this.enterpriseServices.map((item) => {
        item.isactive = false;
      });

      enterpriseService.isactive = true;
    },
    delayedinitItem() {
      this.initItem();
    },

    initItem() {
      console.log("out");
      //TODO 变更点击项状态与布局
      this.enterpriseServices.map((item) => {
        item.isactive = false;
      });
    },

    changecustomsAdministrationTab(tab) {
      this.customsAdministrationTabs.map((item) => {
        item.isactive = false;
      });

      tab.isactive = true;

      this.currentCustomsAdministrationTab = tab;
      console.log(this.currentCustomsAdministrationTab)
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    },
  },
};
</script>

<style lang="scss" scoped>
.added-services-content {
  display: flex;
  justify-content: center;
  align-items: center;
  .left {
    width: 778px;
    height: 396px;
    background: url("../../assets/zzfwbg.png");
    position: relative;

    .leftimage {
      position: absolute;
      bottom: -45px;
      left: -41px;
    }
    .textcontent {
      position: absolute;
      left: 345px;
      text-align: left;
      top: 26px;
    }
    .title {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
    }

    .desc {
      margin-top: 30px;
      font-size: 14px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      line-height: 30px;
    }

    .btn {
      margin-top: 78px;
      border: 1px solid #08d3ea;
      color: #08d3ea;
    }
  }

  .right {
    width: 422px;
    height: 360px;
    background: #f6f8fc;
    position: relative;

    .right-content {
      position: absolute;
      left: 93px;
      top: 43px;
      text-align: left;
      p {
        margin: 0;
      }
      .index {
        font-size: 85px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #e3e5e9;
      }

      .title {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #383838;
      }

      .desc {
        width: 224px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #939393;
        line-height: 30px;
      }
    }
  }
}

.main {
  .customer-container {
    background: #fafdff;
    .customers-content-container {
      display: flex;
      justify-content: center;
      padding-bottom: 60px;
    }
    .customers {
      display: grid;
      grid-template-columns: repeat(4, 292px);
      gap: 15px;
      grid-auto-rows: minmax(100px);
      max-width: 1200px;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;

      .customer {
        border: 1px solid #efefef;

        background: white;
        width: 292px;
        height: 80px;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .menuHeader {
    padding: 50px;
    .englishtitle {
      background-image: linear-gradient(#e3e3e3, #f6f9fa);
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      font-size: 48px;
      font-family: Arial;
      font-weight: bold;
    }

    .title {
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #1f1f1f;
      margin-top: -10px;
    }

    .desc {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #1f1f1f;
    }
  }

  .enterprise-services {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    padding-bottom: 50px;
    background: #fafdff;

    .status-active {
      display: flex;
      flex-direction: row;
      height: 489px;
      border: 1px solid #002fa7;

      .active-left {
        width: 202px;
        text-align: left;
        background: url(../../assets/qyfwbg.png);
        position: relative;

        .btn {
          background: url("../../assets/btn-bg.png");
          display: flex;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #08d3ea;
          padding: 14px 30px;
          margin-top: 138px;
          background-size: contain;

          align-items: center;

          justify-content: center;
        }

        .active-left-content {
          position: absolute;
          left: 20px;
          top: 60px;
        }

        .servicesicon {
          width: 40px;
          height: 41px;
        }

        .title {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 30px;
        }
        .index {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }

        .linecontainer {
          width: 22px;
          height: 3px;
          background: #ffffff;
          border-radius: 2px;
        }
      }
      .active-right {
        width: 302px;
        background: white;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;

        .desc {
          width: 219px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #939393;
          line-height: 30px;
        }
      }
    }

    .enterprise-service {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      background: white;
      margin: 0 9px;
      padding: 58px 33px;

      max-width: 212px;

      .servicesicon {
        width: 40px;
        height: 41px;
      }
      .title {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        height: 60px;
        line-height: 30px;
      }
      .linecontainer {
        display: flex;

        align-items: center;
        align-content: center;

        justify-content: center;
        .line {
          width: 22px;
          height: 3px;
          background: #e1e1e1;
          border-radius: 2px;
          margin: 41px 0;
        }
      }
      .descContainer {
        display: flex;
        justify-content: center;
      }
      .simple-desc {
        font-size: 14px;
        width: 8em;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b0b0b0;
        line-height: 20px;
      }
    }
  }

  .Customs-Administration {
    display: flex;
    height: 503px;

    .left {
      flex: 1;
      width: 639px;
      background: #ebeff4;
      position: relative;
      .content {
        position: absolute;
        text-align: left;
        right: 36px;
        bottom: 110px;

        .title {
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1d1d1d;
          margin-top: 31px;
        }

        .desc {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1d1d1d;
        }
      }
    }

    .right {
      flex: 2;
      padding-left: 59px;
      text-align: left;
      background: url("../../assets/hgglbg.png");
      background-size: cover;
      color: white;

      .tabs {
        margin-top: 59px;
        margin-bottom: 59px;
        display: flex;

        .normal {
          color: #ffffff;
          min-width: 161px;
          opacity: 0.4;
          cursor: pointer;
        }
        .customs-administration-active {
          opacity: 1;
        }
        .pointline {
          margin-top: 15px;
          display: flex;
          align-items: center;
        }
      }

      .btn {
        border: 1px solid #08d3ea;
        border-radius: 17px;
        width: 110px;
        color: #08d3ea;
        text-align: center;
        padding: 10px 18px;
      }
      .notClick{
        cursor: no-drop;
        border-color:#b6b6b6;
        color: #b6b6b6;
      }
    }
  }

  .public-service-container {
    background: url("../../assets/public-service-bg.png");
    .maincontent {
      display: flex;
      .left {
        flex: 1;
        margin-top: 45px;
        position: relative;

        .leftContent {
          position: absolute;
          left: 20%;
          text-align: left;

          .title {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;

            background: linear-gradient(
              86deg,
              #002fa7 0.87890625%,
              #4484e4 54.7119140625%,
              #54bcfb 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .desc {
            max-width: 400px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #939393;
            margin-top: 22px;
          }

          .btn {
            margin-top: 60px;
            border: 1px solid #b6b6b6;
            color: #b6b6b6;
          }
        }
      }

      .right {
        flex: 1;
        position: relative;

        .rightContent {
          position: absolute;
          left: 128px;
          text-align: left;
          width: calc(100% - 128px);
        }

        .title {
        }

        .desc {
          margin-top: 22px;
          max-width: 450px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #939393;
          line-height: 20px;
        }
      }
    }
  }

  .value-added-services-container {
    background: #ebeff4;
    height: 674px;
  }
}

.leftbg {
  background: url("../../assets/kjdsleftbg.png") no-repeat center;
  background-size: cover;
  height: 805px;
}
.btn {
  width: 110px;
  text-align: center;
  padding: 10px 18px;

  border-radius: 17px;
}

.gradual-change {
  background-image: linear-gradient(#e3e3e3, #e8ecf0);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.title-gradual-change {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;

  background: linear-gradient(
    86deg,
    #002fa7 0.87890625%,
    #4484e4 54.7119140625%,
    #54bcfb 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


</style>