<template>
  <div class="header">
    <div class="left">
      <img class="icon" :src="require('@/assets/logo.png')" />
    </div>

    <el-menu
      :default-active="activeIndex"
      class="myel-menu"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">首页</el-menu-item>
      <el-submenu index="2">
        <template slot="title">企业服务</template>
        <el-menu-item index="2-1">快件申报—B类 </el-menu-item>
        <el-menu-item index="2-2">跨境电商申报—9610出口 </el-menu-item>
        <el-menu-item index="2-3">转关可视化查询 </el-menu-item>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">海关管理</template>
        <el-menu-item index="3-1">跨境电商辅助管理 </el-menu-item>
        <el-menu-item index="3-2">转关可视化监督 </el-menu-item>

        <el-menu-item index="3-3"> 监管场所智能化管理</el-menu-item>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">公共服务</template>
        <el-menu-item index="4-1">跨境电商通关服务 </el-menu-item>
        <el-menu-item index="4-2">监管场所智能化作业 </el-menu-item>

        <el-menu-item index="4-3"> 跨境电商数据可视化 </el-menu-item>
        <el-menu-item index="4-4"> 智慧园区 </el-menu-item>
        <el-menu-item index="4-5"> 妥投管理 </el-menu-item>
      </el-submenu>
      <el-submenu index="5">
        <template slot="title">增值服务</template>
        <el-menu-item index="5-1">实名认证 </el-menu-item>
        <el-menu-item index="5-2">全球商品价格参考库 </el-menu-item>
      </el-submenu>
      <el-submenu index="6">
        <template slot="title">进出口数据</template>
        <el-menu-item index="6-1">数据查询</el-menu-item>
        <el-menu-item index="6-2">数据报告</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>


<script>
export default {
  name: "Header",
  data: function () {
    return { activeIndex: "1" };
  },
  mounted() {
      if (this.$router.currentRoute.path.includes("/DataReport")) {
        this.activeIndex="6-2"
      }
  },
  methods: {
    handleSelect(index) {
      if (this.$router.currentRoute.path.includes("/DataReport")||this.$router.currentRoute.path.includes("/DataSearch")) {
        switch (index) {
          case "6-1":
            // window.open(
              this.$router.push({
                path:"/DataSearch",
              })
            //   "http://guanlicang.cn/DataQuery/index.html#/macro4?tablename=customs_month",
            //   "_blank"
            // );

            break;
          case "6-2":
                 this.$router.push({
              path: "/DataReport",
            });

            break;
          default:
            this.$router.push({
              path: "/",
            });
            break;
        }
      } else {
        switch (index) {
          case "1":
            this.screenPosition("header");

            break;
          case "2-1":
          case "2-2":
          case "2-3":
            this.screenPosition("enterprise-services-container");
            break;
          case "3-1":
          case "3-2":
          case "3-3":
            this.screenPosition("Customs-Administration");

            break;
          case "4-1":

          case "4-2":
            this.screenPosition("public-service-container");

            break;
          case "4-3":
            this.screenPosition("class4-3");
            break;

          case "4-4":
          case "4-5":
            this.screenPosition("class4-4");

            break;
          case "5-1":
          case "5-2":
            this.screenPosition("value-added-services-container");

            break;
          case "6-1":
              this.$router.push({
                path:"/DataSearch",
              })

            break;
          case "6-2":
            this.$router.push({
              path: "/DataReport",
            });

            break;
        }
      }
    },
    screenPosition(className) {
      this.$nextTick(() => {
        let targetbox = document.getElementsByClassName(className);
        targetbox[0].scrollIntoView({ behavior: "smooth" });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {

    position: sticky;
  top: 0;
  left: 0;
  background: white;
  z-index: 100;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 70px;
  .left {
    flex: 2;

    .icon {
      width: 140px;
    }
  }

  .myel-menu {
    flex: 3;
  }
}

div ::v-deep .el-menu--horizontal > .el-menu-item {
  color: #222222;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: bold;
}
div ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #002fa7;
  color: #002fa7;
  font-weight: bold;
}

div ::v-deep .el-menu.el-menu--horizontal {
  border-bottom: none;
}

div ::v-deep .el-submenu .el-submenu__title {
  color: #222222;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: bold;
}
.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title 
{
      background-color: #FFF;
    float: none;
    height: 58px;
    line-height: 58px;
    padding: 0 10px;
    color: #222222 ;
}
.el-menu--horizontal .el-menu .el-menu-item.is-active, .el-menu--horizontal .el-menu .el-submenu.is-active>.el-submenu__title{
      color: #002FA7  ;
}

</style>

<style lang="scss">
.el-menu--popup{
background: #FFFFFF;
box-shadow: 4px 7px 13px 0px rgba(29, 29, 29, 0.41);
border-radius: 4px;
line-height: 58px;
padding: 20px 8px;


.el-menu-item{
  line-height: 58px;

}
}
</style>