import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '../views/Login/Login.vue'
// import Appver from '../views/Appver/Appver.vue'
import Home from '../views/Home/Home.vue'
import Sandtable from '../views/Home/Index.vue'
import DataReport from '../views/Home/DataReport.vue'
import DataSearch from '../views/Home/DataSearch.vue'


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    children: [{
            path: '',
            component: Sandtable
        },
        {
            path: 'DataReport',
            component: DataReport
        }, {
            path: 'DataSearch',
            component: DataSearch
        },

    ]


}]

const router = new VueRouter({
    routes
})

//页面打开之前
router.beforeEach((to, from, next) => {
    next()
})

// 页面打开之后
router.afterEach(to => {
    // document.documentElement.scrollTop = 0 || window.scrollTo(0, 0) // 让页面回到顶部
})

export default router