var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"item"},[_c('el-image',{staticStyle:{"margin-top":"65px","width":"220px"},attrs:{"src":_vm.src}})],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)])]),_c('div',{staticClass:"divider"}),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v("企业服务")]),_c('p',{staticClass:"des"},[_vm._v(" 快件申报—B类"),_c('br'),_vm._v(" 跨境电商申报—9610出口"),_c('br'),_vm._v(" 转关可视化查询"),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v("海关管理")]),_c('p',{staticClass:"des"},[_vm._v(" 转关可视化监督"),_c('br'),_vm._v(" 监管场所智能化管理"),_c('br'),_vm._v(" 智能识图监管"),_c('br'),_vm._v(" 跨境电商辅助管理"),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v("公共服务")]),_c('p',{staticClass:"des"},[_vm._v(" 智慧园区"),_c('br'),_vm._v(" 跨境电商通关服务"),_c('br'),_vm._v(" 跨境电商数据可视化"),_c('br'),_vm._v(" 监管场所智能化作业"),_c('br'),_vm._v(" 妥投管理"),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v("增值服务")]),_c('p',{staticClass:"des"},[_vm._v(" 实名认证"),_c('br'),_vm._v(" 全球商品价格参考库"),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('p',{staticClass:"title"},[_vm._v("进出口数据")]),_c('p',{staticClass:"des"},[_vm._v(" 数据查询 "),_c('br'),_vm._v(" 下载报告"),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright"},[_vm._v(" © CopyRight 2019 北京澎湃信用管理有限公司 "),_c('a',{attrs:{"href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=16033698","target":"_blank"}},[_vm._v("京ICP备16033698号-1")]),_c('br'),_vm._v(" 为了获得更好的用户体验，请使用最新版谷歌浏览器 ")])
}]

export { render, staticRenderFns }