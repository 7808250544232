<template>
  <div class="bottom">
    <div class="content-container">
      <div class="content">
        <div class="item">
          <el-image :src="src" style="margin-top: 65px;width:220px"></el-image>

          <!-- <p class="connact">
            联系我们<br />
            010-64788139<br />
          </p> -->
        </div>

        <div class="item">
          <p class="title">企业服务</p>
          <p class="des">
            快件申报—B类<br />
            跨境电商申报—9610出口<br />
            转关可视化查询<br />
            
          </p>
        </div>

        <div class="item">
          <p class="title">海关管理</p>
          <p class="des">
            转关可视化监督<br />
            监管场所智能化管理<br />
            智能识图监管<br />
            跨境电商辅助管理<br />
          </p>
        </div>

        <div class="item">
          <p class="title">公共服务</p>
          <p class="des">
            智慧园区<br />
            跨境电商通关服务<br />
            跨境电商数据可视化<br />
            监管场所智能化作业<br />
            妥投管理<br />
          </p>
        </div>

        <div class="item">
          <p class="title">增值服务</p>
          <p class="des">
            实名认证<br />
            全球商品价格参考库<br />
          </p>
        </div>

        <div class="item">
          <p class="title">进出口数据</p>
          <p class="des">
            数据查询 <br />
            下载报告<br />
          </p>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div class="copyright">
      © CopyRight 2019 北京澎湃信用管理有限公司 
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=16033698" target="_blank">京ICP备16033698号-1</a>
      <br />
      为了获得更好的用户体验，请使用最新版谷歌浏览器
    </div>
  </div>
</template>

<script>
export default {
  name: "Bottom",

  data() {
    return {
      src: require("@/assets/logo2.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
.bottom {
  background: #1e1e1e;

  .content-container {
    text-align: center;
    width: 100%;
  }
  .content {
    display: flex;
    justify-content: center;
    text-align: left;
    flex-wrap: nowrap;
    align-content: center;
    flex-direction: row;

    .item {
      margin-right: 60px;
      .title {
        margin: 60px 0 19px 0;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
      .des {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #646464;
        line-height: 30px;
      }
      .connact {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  .divider {
    height: 1px;
    background: #353535;
    margin: 16px 0;
  }
  .copyright {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
    opacity: 0.5;
    padding-bottom: 16px;

    a{
      text-decoration:none;
      color: #ffffff;
    
    }
  }
}
</style>