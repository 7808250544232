<template>
  <div v-loading="loading">
    <div class="content">
      <div class="contentLeft">
        <canvas id="the-canvas" style="direction: ltr"></canvas>
        <ul class="pages">
          <li v-for="i in numPages" :class="{isactive:i===index}" :key="i" @click="renderPage(pdf,i)">{{i}}</li>
        </ul>
      </div>


      <div class="fileslist">
        <div v-for="item in list" :key="item.id" class="file">
          <div>
            <label class="label" @click="loadingPdf(item.filePathOne)">{{ item.fileNameOne }}</label>
            <i class="el-icon-download" @click="download(item)"></i>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
  import axios from "axios";
  export default {
    name: "Home",
    data() {
      return {
        index: 1,
        loading: false,
        list: [],
        src: "",
        numPages: 1,
        pdf: '',
      };
    },
    mounted() {
      this.loading = true;
      axios
        .get("http://guanlicang.cn/jeecg-boot/demo/file/list")
        .then(({ data }) => {
          this.list = data.result.records;
          this.src = this.list[0].filePathOne;

          this.loadingPdf(this.src);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    methods: {
      loadingPdf(pdfurl) {
        pdfurl = encodeURI(pdfurl)
        if (process.env.NODE_ENV === "development") {
          pdfurl = pdfurl.replace("http://www.shujuquan.com", "/api");
        }
        const loadingTask = pdfjsLib.getDocument(pdfurl);
        (async () => {
          let pdf = await loadingTask.promise;
          this.pdf = pdf
          this.numPages = pdf.numPages
          console.log(this.numPages)
          this.renderPage(pdf, 1)
        })();
      },
      async renderPage(pdf, pagenumber) {
        this.index = pagenumber
        const page = await pdf.getPage(pagenumber);

        var scale = 1.5;
        var viewport = page.getViewport({ scale: scale });
        // Support HiDPI-screens.
        var outputScale = window.devicePixelRatio || 1;

        var canvas = document.getElementById("the-canvas");
        var context = canvas.getContext("2d");

        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);
        canvas.style.width = Math.floor(viewport.width) + "px";
        canvas.style.height = Math.floor(viewport.height) + "px";

        var transform =
          outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

        var renderContext = {
          canvasContext: context,
          transform: transform,
          viewport: viewport,
        };
        page.render(renderContext);
      },
      download(item) {
        window.open(item.filePathTwo, "");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    position: relative;
    height: 90vh;
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
.contentLeft{
  width: 60%;
}

  .pages {
    display: flex;
    justify-content: center;
  }

  .fileslist {
    /* position: absolute;
    right: 5%;
    top: 150px; */
    margin-top: 150px;

    .file {
      font-size: 16px;
      padding: 8px 0;
      cursor: pointer;


      .label {
        padding: 4px 16px;
        cursor: pointer;

      }
    }
  }

  ul {
    list-style: none;
  }

  ul.pages li {
    color: blue;
    padding: 8px;
    cursor: pointer;
  }

  ul.pages .isactive {
    text-decoration: underline;

  }

  @media screen and (max-width:1369px) {
    .fileslist {
      position: absolute;
      right: 0;
      top: 150px;
    }
  }
</style>